const httpService = require('../../../services/api');
import {
    CHECK,
    REGISTER,
    LOGIN,
    LOGOUT,
    FETCH_USER_NOTIFICATIONS,
    SAVE_NOTIFICATION,
    USER_NOTIFICATION_READED
} from './mutation-types';
import store from '@state/store';
import Api from '@services/api';
import storage from '@services/storage';

export default {
    async [CHECK]() {
        const token = localStorage.getItem('x-access-token');
        const user = JSON.parse(storage.getItem('user'));

        if (token && user) {
            store.dispatch('auth/login', { token, user });
        } else if (token && (!user)) {
            const data = await Api.get('validate-token');
            store.dispatch('auth/login', { token, user: data.user });
        } else {
            store.dispatch('auth/logout');
        }
    },

    [LOGIN](state, token) {
        state.token = token;
        state.authenticated = true;
        localStorage.setItem('x-access-token', token);
    },

    async [LOGOUT](state) {
        state.token = null;
        state.authenticated = false;

        try {
            const token = storage.getItem('x-access-token');
            if (token) await Api.get('logout');
            storage.removeItem('x-access-token');
        } catch (err) {
            storage.removeItem('x-access-token');
        }
    },
};
