/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

import Vue from 'vue';
import router from "@/router";
import store from '@state/store';
import * as types from './mutation-types';

export const check = ({
    commit
}) => {
    commit(types.CHECK);
};

/* eslint-disable no-unused-vars */
// export const register = ({
//     commit
// }) => {
//     Vue.router.push({
//         name: 'login',
//     });
// };

export const login = ({
    commit
}, data) => {
    commit(types.LOGIN, data.token);

    store.dispatch('account/save', data.user);
    let name = "manage.noc.list";

    if (data.user.role === "super_admin") {
        name = 'manage.noc.list';
    } else if (data.user.role === "dealer") {
        name = "dealer.noc.list";
    }

    router.push({
        name,
    });
};

export const logout = ({
    commit
}) => {
    commit(types.LOGOUT);

    store.dispatch('account/remove');

    router.push({
        name: 'login',
    });
};

export default {
    check,
    login,
    logout
};
