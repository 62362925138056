import router from "@/router";
import { ElMessage } from "element-plus";
import {
    isEmpty,
    orderBy,
    flattenDeep
} from "lodash";

const service = {
    goToPage(name = "home", data = {}, type = "params") {
        const obj = {
            name,
        };
        if (!isEmpty(data)) obj[type] = data;
        router.push(obj);
    },
    redirectToLink(url) {
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
    showSuccessMessage(message) {
        ElMessage({
            duration: 3000,
            type: "success",
            message,
            showClose: true
        });
    },
    showErrorMessage(message) {
        ElMessage({
            duration: 3000,
            type: "error",
            message,
            showClose: true
        });
    },
    showWarningMessage(message) {
        ElMessage({
            duration: 3000,
            type: "warning",
            message,
            showClose: true
        });
    },
    loginValidation(email, password) {
        let validate = {
            message: "",
            isValid: true
        };
        if (!email) {
            validate.message = "Email is requried!";
            validate.isValid = false;
        } else if (!password) {
            validate.message = "Password is requried!";
            validate.isValid = false;
        }

        return validate;
    },

    nocValidation(data) {
        if (!data.name) {
            return {
                message: "Name is required!",
                isValid: false,
            };
        }
        if (!data.phone) {
            return {
                message: "Phone is required!",
                isValid: false,
            };
        }
        if (data.phone && data.phone.length !== 10) {
            return {
                message: "Phone number must be 10 digit!",
                isValid: false,
            };
        }
        if (data.email && !data.email.match(/[a-zA-Z_.0-9]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
            return {
                message: "Email not formated properly!",
                isValid: false,
            };
        }
        if (!data.state) {
            return {
                message: "state is required!",
                isValid: false,
            };
        }
        if (!data.type) {
            return {
                message: "Registration type is required!",
                isValid: false,
            };
        }
        if (!data.motorNumber) {
            return {
                message: "Motor number is required!",
                isValid: false,
            };
        }
        if (!data.chassisNumber) {
            return {
                message: "Chassis number is required!",
                isValid: false,
            };
        }
        // if (data.chassisNumber && data.chassisNumber.length !== 17) {
        //     return {
        //         message: "Chassis number must be of 17 digit!",
        //         isValid: false,
        //     };
        // }

        // if (!data.chassisNumber.match(/[a-zA-Z0-9]{9}[a-zA-Z0-9-]{2}[0-9]{6}/g)) {
        //     return {
        //         message: "Chassis number is not valid",
        //         isValid: false,
        //     };
        // }
        if (!data.vehicleType) {
            return {
                message: "Vehicle type is required!",
                isValid: false,
            };
        }
        return {
            isValid: true,
            message: ""
        };

    },
    dealerValidation(data) {
        if (!data.email) {
            return {
                message: "Email is required!",
                isValid: false,
            };
        }
        if (!data.email.match(/[a-zA-Z_.0-9]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
            return {
                message: "Please enter a valid email!",
                isValid: false,
            };
        }
        if (!data.password) {
            return {
                message: "Password is required!",
                isValid: false,
            };
        }
        if (!data.shopName) {
            return {
                message: "Business Name is required!",
                isValid: false,
            };
        }
        if (!data.panNumber) {
            return {
                message: "PAN number is required!",
                isValid: false,
            };
        }
        if (data.panNumber && data.panNumber.length !== 10) {
            return {
                message: "PAN number must be of 10 digit!",
                isValid: false,
            };
        }
        if (!data.gstNumber) {
            return {
                message: "GST number is required!",
                isValid: false,
            };
        }
        if (data.gstNumber && data.gstNumber.length !== 15) {
            return {
                message: "GST number must be of 15 digit!",
                isValid: false,
            };
        }
        if (!data.state) {
            return {
                message: "state is required!",
                isValid: false,
            };
        }
        if (!data.address) {
            return {
                message: "Address is required!",
                isValid: false,
            };
        }
        if (!data.name) {
            return {
                message: "Name is required!",
                isValid: false,
            };
        }
        if (!data.phone) {
            return {
                message: "Phone is required!",
                isValid: false,
            };
        }
        if (data.phone && data.phone.length !== 10) {
            return {
                message: "Phone number must be 10 digit!",
                isValid: false,
            };
        }

        // if (!data.panNumber.match(/^([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$/)) {
        //     return {
        //         message: "PAN number is not valid",
        //         isValid: false,
        //     };
        // }
        return {
            isValid: true,
            message: ""
        };

    },
};

export default service;
