/* ============
 * Getters for the account module
 * ============
 *
 * The getters that are available on the
 * account module.
 */

function showLedger(state) {
    if (state.user && state.user.userType === 'vendor') {
        if (state.user && state.user.vendor) return state.user.vendor.showLedger
    }

    return false;
}

export default {
    getUserRole: state => state.user.role,
    loggedInUser: state => state.user,
    loggedInUserId: state => state.user.id,
    getDealer: state => state.user.Dealer,
    isSuperAdmin: state => state.user.role === 'super_admin',
    isDealer: state => state.user.role === 'dealer',
};
