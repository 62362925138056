/* ============
 * State of the account module
 * ============
 *
 * The initial state of the account module.
 */

export default {
    user: {},
};
