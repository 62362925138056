<script>
import Spinner from "@components/spinner";

export default {
    name: "App",
    components: {
        Spinner,
    },
};
</script>
<template>
    <spinner />
    <router-view />
</template>

<style src="@design/tailwind.css" />
