/* ============
 * Mutations for the account module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import {
    FIND,
    SAVE,
    REMOVE,
    UPDATE,
    SAVE_ADMIN,
    UPDATE_DEALER
} from './mutation-types';
import storage from '@services/storage';

export default {
    [FIND](state, user) {
        state.email = user.email;
        state.fullName = user.fullName;
        // state.lastName = user.lastName;
    },
    [SAVE](state, user) {
        state.user = user;
        storage.setItem('user', JSON.stringify(user));
    },
    [SAVE_ADMIN](state, admin) {
        state.admin = admin;
        storage.setItem('admin', JSON.stringify(admin));
    },
    [REMOVE](state) {
        state.user = {};
        storage.removeItem('user');
        state.admin = {};
        storage.removeItem('admin');
    },
    [UPDATE](state, payload) {
        state.user = payload;
        Object.keys(user).forEach((k) => {
            //     // Vue.set(state.user, k, user[k]);
        });
        storage.setItem('user', JSON.stringify({ ...state.user }));
    },
    [UPDATE_DEALER](state, payload) {
        state.user.Dealer = payload;
        // Object.keys(user).forEach((k) => {
        //     // Vue.set(state.user, k, user[k]);
        // });
        storage.setItem('user', JSON.stringify({ ...state.user }));
    },
};
