import { createStore, createLogger } from 'vuex'
import createPersistedState from "vuex-persistedstate";
// import modules from './modules'
// Modules
import account from './modules/account/index';
import auth from './modules/auth/index';
import loader from './modules/loader';
import services from '@services';

const { storage } = services

const debug = process.env.NODE_ENV === 'development';

export default createStore({
    /**
     * Assign the modules to the store.
     */
    modules: {
        account,
        auth,
        loader
    },

    /**
     * If strict mode should be enabled.
     */
    strict: debug,

    /**
     * Plugins used in the store.
     */
    plugins: debug ? [
        createLogger(),
        createPersistedState({
            storage: {
                getItem: key => storage.getItem(key),
                setItem: (key, value) => storage.setItem(key, value),
                removeItem: key => storage.removeItem(key)
            }
        })
    ] : [],
});
