import { createRouter, createWebHistory } from 'vue-router';
import store from '@state/store';
import routes from './routes';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {

    store.dispatch("loader/updateLoader", true);
    const isDealer = store.getters["account/isDealer"]

    if (to.matched.some(m => m.meta.admin) && (!store.state.auth.authenticated)) {
        next({
            name: 'login',
        });
    } else if (to.matched.some(m => m.meta.auth) && !store.state.auth.authenticated) {
        next({
            name: 'login',
        });
    } else if (to.matched.some(m => m.meta.guest) && store.state.auth.authenticated) {
        next({
            name: isDealer ? "dealer.noc.list" : "admin.dashboard"
        });
    } else if (to.matched.some(m => m.meta.dealer) && !store.state.auth.authenticated) {
        next({
            name: "login"
        });
    } else {
        next();
    }
});

router.afterEach((to, from, next) => {
    store.dispatch("loader/updateLoader", false);
});

export default router;
