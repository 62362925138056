<script>
import { mapGetters } from "vuex";
export default {
    name: "SpinnerComponent",
    computed: {
        ...mapGetters("loader", ["isLoading"])
    },
};
</script>

<template lang="pug">
.spinner(v-if="isLoading")
    #loader.loader
        span
        span
        span
        span
</template>
