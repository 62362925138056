import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import ElementUi from "element-plus";
import 'element-plus/theme-chalk/index.css';
import services from './services';
import Filters from "./filters";

const app = createApp(App);
app.use(router);
app.use(store);
app.use(ElementUi);

// Vue configurations
app.config.devtools = true;
app.config.globalProperties.$utility = services.utilities;
app.config.globalProperties.$http = services.api;
app.config.globalProperties.$filters = Filters;


// if (process.env.NODE_ENV === 'development') {
//     if ('__VUE_DEVTOOLS_GLOBAL_HOOK__' in window) {
//         window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app;
//     }
// }

app.mount('#app');
