export default [
    //------------------------- Common pages------------------------------
    {
        path: '/',
        name: 'register.noc',
        component: () => import('@views/common/noc-registeration'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@views/common/login'),
        meta: {
            guest: true,
        },
    },
    {
        path: '/dealer',
        name: 'register.dealer',
        component: () => import('@views/common/dealer-registeration'),
    },
    // {
    //     path: '/pay-and-register/:nocId',
    //     name: 'place.order',
    //     component: () => import('@views/common/payment'),
    // },
    {
        path: '/dashboard',
        name: 'admin.dashboard',
        component: () => import('@views/admin/dashboard'),
        meta: {
            admin: true,
        },
    },
    {
        path: '/manage-noc',
        name: 'manage.noc.list',
        component: () => import('@views/admin/manage-noc'),
        meta: {
            admin: true,
        },
    },
    {
        path: '/noc-detail/:nocId',
        name: 'noc.detail',
        component: () => import('@views/admin/manage-noc/details'),
    },
    {
        path: '/validate/:guidId',
        name: 'validate.noc',
        component: () => import('@views/common/noc-details'),
    },
    {
        path: '/manage-dealer',
        name: 'manage.dealer.list',
        component: () => import('@views/admin/manage-dealer'),
        meta: {
            admin: true,
        },
    },
    {
        path: '/noc-registered/:nocId/thank-you',
        name: 'noc.thank.you',
        component: () => import('@views/common/thank-you'),
    },
    {
        path: '/registered/:nocId/thank-you',
        name: 'dealer.thank',
        component: () => import('@views/common/thank-you'),
    },
    {
        path: '/dealer-noc',
        name: 'dealer.noc.list',
        component: () => import('@views/dealer/manage-noc'),
        meta: {
            dealer: true
        }
    },
    {
        path: '/dealer-detail/:dealerId',
        name: 'dealer.detail',
        component: () => import('@views/admin/manage-dealer/details'),
        meta: {
            admin: true
        }
    },
    {
        path: '/register-noc',
        name: 'register.dealer.noc',
        component: () => import('@views/dealer/register-noc'),
        meta: {
            dealer: true
        }
    },
    {
        path: '/ledger',
        name: 'ledger',
        component: () => import('@views/admin/ledger'),
        meta: {
            admin: true
        }
    },
    {
        path: '/balance-history',
        name: 'dealer.ledger',
        component: () => import('@views/admin/ledger'),
        meta: {
            dealer: true
        }
    },
    // --------------------------- Default routing --------------------------------------

    {
        path: '/*',
        redirect: '/login',
    }
];
