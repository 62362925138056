import SecureLS from "secure-ls";

const ls = new SecureLS({
    isCompression: false,
    encryptionSecret: process.env.VUE_APP_STORAGE_SECRET
});

const storage = {
    getItem: function(key)  {
        return ls.get(key)
    },
    setItem: function(key, value)  {
        ls.set(key, value)
    },
    removeItem: function(key)  {
        ls.remove(key)
    },    
    clearStorage: function()  {
        ls.removeAll();
    },
}

export default storage